import React, { useContext, useEffect, useState } from 'react';

import { useNavigate } from 'react-router-dom';

import {
    Button,
    Dialog,
    Typography,
    Divider,
    CircularProgress,
    Avatar,
} from '@mui/material';

import { UserContext } from '../../core/user';

import { fetchInitMyPageInfo } from '../../services/myPage/MyPageServices';
import { fetchSyncCloudInstances } from '../../services/csp/awsServices';

import RegisterCSP from '../csp/RegisterCSP';
import RegisterAWS from '../csp/RegisterAWS';
import RegisterAzure from '../csp/RegisterAzure';
import RegisterOpenStack from '../csp/RegisterOpenStack';

import aws_logo from '../../assets/images/AWS_Logo.svg';
import azure_logo from '../../assets/images/Azure_Logo.svg';
import openstack_logo from '../../assets/images/Openstack_Logo.svg';

import theme from '../../theme';

const CSP_COMPONENTS ={
    'AWS': RegisterAWS,
    'Azure': RegisterAzure,
    'Openstack': RegisterOpenStack,
}

const MyPage = () => {
    const navigate = useNavigate();

    const { userState, setUserState } = useContext(UserContext);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [selectedCSP, setSelectedCSP] = useState(null);

    const [isCloudSyncLoading, setIsCloudSyncLoading] = useState(false);
    const [isCloudSyncErrorMsg, setIsCloudSyncErrorMsg] = useState('');
    const [isCloudSyncSuccess, setIsCloudSyncSuccess] = useState(false);

    const SelectedCSPComponent = CSP_COMPONENTS[selectedCSP];

    const [company, setCompany] = useState('');
    const [csp, setCSP] = useState('');

    useEffect(() => {    
        fetchData();
    }, []);
    
    const fetchData = async () => {
        try {
            const response = await fetchInitMyPageInfo(userState.com_id);
            
            const data = await response.json();
            
            setCompany(data.company);
            setCSP(data.csp);
            setCSP(...{"azure": true});
            setCSP(...{"openstack": true});
            console.log('data', csp);
        } catch (error) {
            console.error('error', error);
        }
    };

    const handleClickOpen = () => {
        setSelectedCSP(null);
        setDialogOpen(true);
    };

    const handleClickSync = async () => {
        setIsCloudSyncLoading(true);
        setIsCloudSyncErrorMsg('');
        setIsCloudSyncSuccess(false);

        try{
            const response = await fetchSyncCloudInstances(userState.com_id); 

            if (response.status === 200) {
                setIsCloudSyncSuccess(true);
            } else {
                const errorData = await response.json();
                setIsCloudSyncErrorMsg(errorData.message);
            }
        } catch (error) {
            setIsCloudSyncErrorMsg('An error occurred while syncing cloud');
        } finally {
            setIsCloudSyncLoading(false);
        }
    };

    const handleClose = () => {
        setDialogOpen(false);
    };

    const returnConnectedCloud = () => {
        const cloudServices = [
            { key: "aws", logo: aws_logo, name: "AWS" },
            { key: "azure", logo: azure_logo, name: "Azure" },
            { key: "openstack", logo: openstack_logo, name: "OpenStack" },
        ];
    
        return cloudServices
            .filter(service => csp[service.key])  // ✅ csp에 해당 키가 있는 것만 필터링
            .map(service => (
                <Avatar
                    key={service.key}
                    sx={{
                        ...theme.typography.smallAvatar,
                        backgroundColor: 'transparent',
                        marginRight: '10px',
                        border: '1px solid dark',
                    }}
                >
                    <img src={service.logo} alt={service.name} />
                </Avatar>
            ));
    };
    

    return (
        <div className="flex flex-row bg-white h-full p-10 m-10 space-x-5">
            <Dialog
                open={dialogOpen}
                onClose={handleClose}
                maxWidth="xl"
                // fullWidth={true}
            >
                {selectedCSP ? (
                     <SelectedCSPComponent onBack={() => setSelectedCSP(null)} />
                ) : (
                    <RegisterCSP onSelectCSP={setSelectedCSP} />
                )}
            </Dialog>
            <div className='space-y-5'>
                <Typography
                    variant="h5"
                    sx={{
                        fontFamily: 'Roboto-Regular',
                        fontWeight: 'bold',
                    }}
                >
                    My Information
                </Typography>
                <Divider />
                <div className="flex w-fit flex-col justify-start border-solid border-gray-400 border-2 p-5">
                    <div className="flex flex-row justify-start items-center p-2 divide-x-2">
                        <Typography
                            variant="h6"
                            sx={{ fontWeight: 'bold' }}
                            className="w-[150px]"
                        >
                            User
                        </Typography>
                        <div className="px-4">
                            <Typography
                                variant="h6"
                                className="px-2"
                                sx={{ fontWeight: 200 }}
                            >
                                {userState.username}
                            </Typography>
                        </div>
                    </div>
                    <div className="flex flex-row justify-start items-center p-2 divide-x-2">
                        <Typography
                            variant="h6"
                            sx={{ fontWeight: 'bold' }}
                            className="w-[150px]"
                        >
                            Company
                        </Typography>
                        <div className="px-4">
                            <Typography
                                fullWidth
                                variant="outlined"
                                size="small"
                            >
                                {company.company_name}
                            </Typography>
                        </div>
                    </div>
                    <div className="flex flex-row justify-start items-center p-2 divide-x-2">
                        <div className="w-[150px]">
                            <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                                E-mail
                            </Typography>
                        </div>
                        <div className="px-4 w-[300px]">
                            <Typography
                                fullWidth
                                variant="outlined"
                                size="small"
                            >
                                {userState.email}
                            </Typography>
                        </div>
                    </div>
                </div>
            </div>
            <div className='space-y-5'>
                <div className="flex flex-row justify-between items-center">
                    <Typography
                        variant="h5"
                        sx={{
                            fontFamily: 'Roboto-Regular',
                            fontWeight: 'bold',
                            lineHeight: '32.1px',
                        }}
                    >
                        CSP Information
                    </Typography>
                    <Button
                        className='ml-auto'
                        variant="contained"
                        color="primary"
                        sx={{
                            fontSize: "18px",
                            height: "32.1px",
                            alignItems: "center", // 내부 텍스트를 수직 중앙 정렬
                        }}
                        onClick={handleClickOpen}
                    >
                        Add CSP
                    </Button>
                </div>
                <Divider />
                <div className="flex flex-col justify-center w-full border-solid border-gray-400 border-2 p-5">
                    <div className="flex flex-row justify-start items-center p-2 divide-x-2">
                        <Typography
                            variant="h6"
                            sx={{ fontWeight: 'bold' }}
                            className="w-[200px]"
                        >
                            Connected Cloud 
                        </Typography>
                        <Button
                            className='ml-auto'
                            variant="contained"
                            color="primary"
                            sx={{
                                fontSize: "18px",
                                height: "32.1px",
                                alignItems: "center", // 내부 텍스트를 수직 중앙 정렬
                            }}
                            onClick={handleClickSync}
                        >
                            {isCloudSyncLoading ? <CircularProgress size={24} color="inherit" /> : "SYNC"}
                    </Button>
                    </div>
                    {returnConnectedCloud()}
                </div>
            </div>
        </div>
    );
};

export default MyPage;
