import AppConfig from "../../core/config";
import { fetchRefresh } from "../AuthServices";

export const fetchInitMyPageInfo = async (uid) => {
    const url = `${AppConfig.api_base_url}/mypage/${uid}`;
    const response = await fetch(url, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
    });

    if (response.status === 401 || response.status === 422) {
        const refreshResponse = await fetchRefresh();

        if (refreshResponse.status === 200) {
            const retryResponse = await fetch(url, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
            });

            return retryResponse;
        }
    }
    return response;
}

