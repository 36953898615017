import AppConfig from '../../core/config';
import { fetchRefresh } from '../AuthServices';

export const fetchCreateAWSCredential = async (credentials, com_id) => {
    const url = `${AppConfig.aws_base_url}/auth/register/${com_id}`;
    const response = await fetch(url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
        body: JSON.stringify(credentials),
    });

    if (response.status === 401 || response.status === 422) {
        const refreshResponse = await fetchRefresh();

        if (refreshResponse.status === 200) {
            const retryResponse = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${localStorage.getItem('token')}`,
                },
                body: JSON.stringify(credentials),
            });

            return retryResponse;
        }
    }
    return response;
};

export const fetchSyncCloudInstances = async (com_id) => {
    const url = `${AppConfig.aws_base_url}/auth/sync_instances/${com_id}`;
    // get api
    const response = await fetch(url, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
    });

    if (response.status === 401 || response.status === 422) {
        const refreshResponse = await fetchRefresh();

        if (refreshResponse.status === 200) {
            const retryResponse = await fetch(url, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${localStorage.getItem('token')}`,
                },
            });

            return retryResponse;
        }
    }
    return response;
}