import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import {
    TextField,
    InputAdornment,
    Button,
    Typography,
    Radio,
    RadioGroup,
    FormControlLabel,
    FormControl,
    FormLabel
} from '@mui/material';

import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import PasswordIcon from '@mui/icons-material/Password';
import BusinessIcon from '@mui/icons-material/Business';

import { fetchSignUp } from '../../services/AuthServices';

const SignUp = () => {
    const navigate = useNavigate();

    const [username, setUsername] = useState('');
    const [usernameError, setUsernameError] = useState(false);
    const [usernameErrorText, setUsernameErrorText] = useState('');
    
    const [email, setEmail] = useState('');
    const [emailError, setEmailError] = useState(false);
    const [emailErrorText, setEmailErrorText] = useState('');

    const [password, setPassword] = useState('');
    const [passwordError, setPasswordError] = useState(false);
    const [passwordErrorText, setPasswordErrorText] = useState('');
    
    const [confirmPassword, setConfirmPassword] = useState('');
    const [confirmPasswordError, setConfirmPasswordError] = useState(false);

    const [company_name, setCompany_Name] = useState('');
    const [companyNameError, setCompanyNameError] = useState(false);
    const [companyNameErrorText, setCompanyNameErrorText] = useState('');

    const [isAdmin, setIsAdmin] = useState(true);

    const onSubmit = async (e) => {
        e.preventDefault();

        if (password !== confirmPassword) {

            setConfirmPasswordError(true);
            return;
        }

        const signUpForm = {
            username,
            email,
            password,
            company_name,
            is_admin: isAdmin,
        }

        const response = await fetchSignUp(signUpForm);

        if (response.ok) {
            alert('Sign up successful');
            navigate('/SignIn');
        } else {
            errorCheck(response);
        }
    };

    const errorCheck = async (response) => {
        const errorText = await response.text();

        setUsernameError(false);
        setEmailError(false);
        setPasswordError(false);
        setCompanyNameError(false);

        try {
            const errorJson = JSON.parse(errorText);
            console.log(errorJson);

            errorJson.detail.forEach((error) => {
                if (error.loc[0] === 'body') {
                    const field = error.loc[1];
                    if (field === 'username') {
                        setUsernameError(true);
                        setUsernameErrorText(error.msg);
                    } else if (field === 'email') {
                        setEmailError(true);
                        setEmailErrorText(error.msg);
                    } else if (field === 'password') {
                        setPasswordError(true);
                        setPasswordErrorText(error.msg);
                    } else if (field === 'company_name') {
                        setCompanyNameError(true);
                        setCompanyNameErrorText(error.msg);
                    }
                }
            });
        } catch (error) {
            console.log(error);
        }
    }


    const handleKeyDown = (e) => {
        console.log(e.key);
        if (e.key === 'Enter') {
            onSubmit(e);
        }
    };

    return (
        <div className='flex flex-col items-center'>
            <Typography component="h1" variant="h5">
                    Sign up
            </Typography>
            <FormControl>
                <FormLabel id="demo-controlled-radio-buttons-group">Account Type</FormLabel>
                <RadioGroup
                    row
                    aria-labelledby="demo-controlled-radio-buttons-group"
                    name="controlled-radio-buttons-group"
                    value={isAdmin}
                    onChange={(e) => setIsAdmin(e.target.value)}
                >
                    <FormControlLabel value={true} control={<Radio />} label="Admin" />
                    <FormControlLabel value={false} control={<Radio />} label="User" />
                </RadioGroup>
            </FormControl> 
            <TextField
                margin="normal"
                required
                fullWidth
                label="Name"
                name="name"
                autoComplete="name"
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <PersonOutlineIcon />
                        </InputAdornment>
                    ),
                }}
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                // error={!!formErrors.username}
                // helperText={formErrors.username}
                onKeyDown={handleKeyDown}
            />
            {usernameError &&
                <Typography 
                    variant="caption" 
                    color="error"
                >
                    {usernameErrorText}
                </Typography>
            }
            <TextField
                margin="normal"
                required
                fullWidth
                label="Email"
                name="email"
                autoComplete="email"
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <PersonOutlineIcon />
                        </InputAdornment>
                    ),
                }}
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                // error={!!formErrors.username}
                // helperText={formErrors.username}
                onKeyDown={handleKeyDown}
            />
            {emailError && 
                <Typography 
                    variant="caption" 
                    color="error"
                >
                    {emailErrorText}
                </Typography>
            }
            <TextField
                margin="normal"
                required
                fullWidth
                label="Password"
                // type="password"
                id="password"
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <PasswordIcon />
                        </InputAdornment>
                    ),
                }}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                onKeyDown={handleKeyDown}
            />
            {passwordError &&
                <Typography 
                    variant="caption" 
                    color="error"
                >
                    {passwordErrorText}
                </Typography>
            }
            <TextField
                margin="normal"
                required
                fullWidth
                label="Confirm Password"
                type="password"
                id="confirmPassword"
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <PasswordIcon />
                        </InputAdornment>
                    ),
                }}
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                onKeyDown={handleKeyDown}
            />
            {confirmPasswordError &&
                <Typography 
                    variant="caption" 
                    color="error"
                >
                    Passwords do not match
                </Typography>
            }
            <TextField
                margin="normal"
                required
                fullWidth
                label="Company"
                name="companyName"
                autoComplete="companyName"
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <BusinessIcon />
                        </InputAdornment>
                    ),
                }}
                value={company_name}
                onChange={(e) => setCompany_Name(e.target.value)}
                onKeyDown={handleKeyDown}
            />
            {companyNameError &&
                <Typography 
                    variant="caption" 
                    color="error"
                >
                    {companyNameErrorText}
                </Typography>
            }
            <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
                onClick={onSubmit}
            >
                Sign Up
            </Button>
        </div>
    );
}

export default SignUp;