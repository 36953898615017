import React, { useState } from 'react';
import { useParams } from 'react-router-dom';

import {
    TextField,
    Button,
    Card,
    CardContent,
    Typography,
    Box,
    Container,
    Tooltip,
    IconButton,
} from '@mui/material';
import {
    CloudOutlined,
    KeyOutlined,
    VpnKeyOutlined,
    PublicOutlined,
    InfoOutlined,
    DeleteOutline,
    ContentCopyOutlined,
} from '@mui/icons-material';
import { styled } from '@mui/material/styles';

// import { fetchCreateAWSCredential } from '../../../services/AWSCredentialServices';

// import { useApi } from '../../../core/hooks';

const StyledCard = styled(Card)(({ theme }) => ({
    maxWidth: 500,
    margin: '40px auto',
    boxShadow: '0 8px 24px rgba(0,0,0,0.12)',
    borderRadius: 16,
    background: 'linear-gradient(145deg, #ffffff 0%, #f5f5f5 100%)',
}));

const Form = styled('form')({
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
});

const InputContainer = styled(Box)({
    position: 'relative',
});

const RegisterOpenStack = ({ onSuccess, onClose }) => {

    const [showSecret, setShowSecret] = useState(false);

    const handleSubmit = async (e) => {
        // e.preventDefault();
        // try {
        //     const data = await callApi(() =>
        //         fetchCreateAWSCredential(iid, formData),
        //     );
        //     onSuccess();
        //     onClose();
        // } catch {
        //     onClose();
        // }
    };

    return (
        <Container maxWidth="sm">
            <StyledCard>
                <CardContent sx={{ p: 4 }}>
                    <Box display="flex" alignItems="center" mb={3}>
                        <CloudOutlined
                            sx={{ fontSize: 40, color: '#ee1142', mr: 2 }}
                        />
                        <Typography variant="h4" fontWeight="600">
                            OpenStack Connection
                        </Typography>
                    </Box>
                    <Form onSubmit={handleSubmit}>
                        <Button
                            variant="contained"
                            size="large"
                            type="submit"
                            sx={{
                                mt: 2,
                                height: 56,
                                background:
                                    'linear-gradient(45deg, #D71920  30%, #A61717 90%)',
                                boxShadow: "0 3px 5px 2px rgba(215, 25, 32, .3)",
                                '&:hover': {
                                    background:
                                        'linear-gradient(45deg, #B5171C 30%, #8A1315 90%)',
                                },
                            }}
                            startIcon={<CloudOutlined />}
                        >
                            {/* Connect to Azure */}
                            Service Unavailable
                        </Button>
                    </Form>
                </CardContent>
            </StyledCard>
        </Container>
    );
};

export default RegisterOpenStack;
