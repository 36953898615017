import React from 'react';
import { createBrowserRouter } from 'react-router-dom';

// layouts
import MainLayout from '../layouts/MainLayout';
import ErrorLayout from '../layouts/ErrorLayout';
import ProjectLayout from '../layouts/ProjectLayout';

// routes
import RedirectionRoute from '../components/route/RedirectionRoute';
import ProtectedRoute from '../components/route/ProtectedRoute';

import SignIn from '../pages/authentication/SignIn';
import SignUp from '../pages/authentication/SignUp';

import MyPage from '../pages/myPages/MyPage';

import RegisterCSP from '../pages/csp/RegisterCSP';

import Dashboard from '../pages/dashboard/Dashboard';
import AccessControlListPage from '../pages/pnu_acl/AccessControlListPage';

const router = createBrowserRouter([
    {
        path: '/',
        element: <MainLayout />,
        errorElement: <ErrorLayout />,
        children: [
            {
                index: true,
                element: <RedirectionRoute />,
            },
            {
                path: 'SignIn',
                element: <SignIn />,
            },
            {
                path: 'SignUp',
                element: <SignUp />,
            },
            {
                path: 'Project',
                element: (
                    <ProtectedRoute>
                        <ProjectLayout />
                    </ProtectedRoute>
                ),
                children: [
                    {
                        index: true,
                        element: <Dashboard />,
                    },
                ]
            },
            {
                path: 'ACL',
                element: (
                    <ProtectedRoute>
                        <ProjectLayout />
                    </ProtectedRoute>
                ),
                children: [
                    {
                        index: true,
                        element: <AccessControlListPage />,
                    },
                ]

            },
            {
                path: 'MyPage',
                element: (
                    <ProtectedRoute>
                        <ProjectLayout />
                    </ProtectedRoute>
                ),
                children: [
                    {
                        index: true,
                        element: <MyPage />,
                    },
                ]
            },
        ],
    },
]);

export default router;
