import React, { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../../core/auth';
import { UserContext } from '../../core/user';
import { fetchSignIn } from '../../services/AuthServices';
import SignInForm from './SignInForm';
import Logo from '../../static/img/csrc_logo_big.jpg';

import {
    Avatar,
    Box,
    Container,
    CssBaseline,
    Divider,
    Typography,
    Grid,
} from '@mui/material';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';

const SignIn = () => {
    const navigate = useNavigate();
    const { setAuthState } = useContext(AuthContext);
    const { setUserState } = useContext(UserContext);
    const [formErrors, setFormErrors] = useState({
        email: '',
        password: '',
    });

    const handleSignIn = async (email, password) => {
        if (validateForm(email, password)) {
            try {
                const response = await fetchSignIn(email, password);
                handleSignInResponse(response);
            } catch (error) {
                alert('Network error. Please try again later.');
                console.log(error);
            }
        }
    };

    const validateForm = (username, password) => {
        const errors = {
            username: username ? '' : 'Please enter your ID.',
            password: password ? '' : 'Please enter your password.',
        };
        setFormErrors(errors);
        return !errors.username && !errors.password;
    };

    const handleSignInResponse = (response) => {
        if (response.ok) {
            setUserState({ isLoading: true });
            setAuthState({ isAuthenticated: true, isLoading: false });
            navigate('/Project');
        } else if (response.status === 401) {
            alert('Invalid username or password.');
        } else {
            alert('Unknown error. Please try again later.');
        }
    };

    return (
        <Grid container sx={{ height: '100vh' }}>
            <LogoSection />
            <Grid item xs={0.1}>
                <Divider orientation="vertical" sx={{ height: '100%' }} />
            </Grid>
            <SignInSection
                handleSignIn={handleSignIn}
                formErrors={formErrors}
            />
        </Grid>
    );
};

const LogoSection = () => (
    <Grid
        item
        xs={5.95}
        sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
    >
        <Box sx={{ textAlign: 'center' }}>
            <img
                src={Logo}
                className="icon-lg"
                alt="CSRC Logo"
                style={{ maxWidth: '100%', height: 'auto' }}
            />
            <Typography
                variant="h6"
                color="secondary"
                sx={{
                    paddingTop: 2,
                    fontWeight: 'bold',
                    fontSize: '2rem',
                }}
            >
                PNU Cloud Access Security Broker
            </Typography>
        </Box>
    </Grid>
);

const SignInSection = ({ handleSignIn, formErrors }) => (
    <Grid
        item
        xs={5.95}
        sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
    >
        <Container component="main" maxWidth="xs">
            <CssBaseline />
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                    <LockOutlinedIcon />
                </Avatar>
                <Typography component="h1" variant="h5">
                    Sign in
                </Typography>
                <SignInForm
                    handleSignIn={handleSignIn}
                    formErrors={formErrors}
                />
            </Box>
        </Container>
    </Grid>
);

export default SignIn;
