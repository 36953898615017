import AppConfig from '../core/config';
import { fetchRefresh } from './AuthServices';

// Fetch ACLs by uid
const fetchInstances = async () => {
    const url = `${AppConfig.api_base_url}/instance/instances`;

    const response = await fetch(url, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
        },
        credentials: 'include',
    });

    if (response.status === 401 || response.status === 422) {
        const refreshResponse = await fetchRefresh();

        if (refreshResponse.status === 200) {
            const retryResponse = await fetch(url, {
                method: 'GET',
                headers: {
                    Accept: 'application/json',
                },
                credentials: 'include',
            });

            return retryResponse;
        }
    }

    return response;
}

const syncInstances = async (uid) => {
    // print(uid);
    const url = `${AppConfig.api_base_url}/instance/sync_instances/${uid}`;  // URL 수정됨!

    const response = await fetch(url, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
        },
        credentials: 'include',
    });

    if (response.ok) {
        console.log("✅ Instances synced successfully");
        // return response;
        return fetchInstances(); // Sync 후 최신 데이터 다시 불러오기
    } else {
        console.error("❌ Failed to sync instances");
        return response;
    }
};

export { fetchInstances, syncInstances };