import React, { useContext, useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../../core/auth';
import { UserContext } from '../../core/user';
import { fetchSignOut } from '../../services/AuthServices';
import HeaderIcon from './HeaderIcon';
import { syncInstances } from '../../services/InstanceServices';
import { styled, useTheme } from '@mui/material/styles';
import {
    AppBar as MuiAppBar,
    Toolbar,
    IconButton,
    Typography,
    Button,
    Menu,
    MenuItem,
    InputBase,
    Box,
    Badge,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import SearchIcon from '@mui/icons-material/Search';
import NotificationsIcon from '@mui/icons-material/Notifications';
import AccountCircle from '@mui/icons-material/AccountCircle';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

const drawerWidth = 240;

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

const Header = ({ open, toggleDrawer }) => {
    const navigate = useNavigate();
    const theme = useTheme();
    const { setAuthState } = useContext(AuthContext);
    const { userState } = useContext(UserContext);
    const [userMenuAnchorEl, setUserMenuAnchorEl] = useState(null);
    const [instances, setInstances] = useState([]);

    const handleNavigation = useCallback(
        (path) => {
            navigate(path);
            setUserMenuAnchorEl(null);
        },
        [navigate],
    );

    const handleSignOut = useCallback(() => {
        fetchSignOut().finally(() => {
            setAuthState({ isAuthenticated: false, isLoading: false });
            handleNavigation('/SignIn');
        });
    }, [setAuthState, handleNavigation]);

    const handleUserMenuClick = useCallback((event) => {
        setUserMenuAnchorEl(event.currentTarget);
    }, []);

    const handleUserMenuClose = useCallback(() => {
        setUserMenuAnchorEl(null);
    }, []);

    const handleSync = async () => {
    if (!userState?.is_admin) {
        alert("❌ 관리자만 Sync 기능을 사용할 수 있습니다.");
        return;
    }

    try {
        console.log("🔄 Syncing instances...");
        console.log("User UID:", userState.uid);

        const response = await syncInstances(userState.uid);
        if (response.ok) {
            const updatedData = await response.json();
            setInstances(updatedData); // 최신 데이터 반영
            alert("✅ Instances synced successfully!");
        } else {
            alert("❌ Failed to sync instances.");
        }
    } catch (error) {
        console.error("Error syncing instances:", error);
        alert("⚠️ Error occurred while syncing.");
    }
};

    const renderUserMenu = () => (
        <Menu
            id="user-menu"
            anchorEl={userMenuAnchorEl}
            open={Boolean(userMenuAnchorEl)}
            onClose={handleUserMenuClose}
            MenuListProps={{ 'aria-labelledby': 'user-button' }}
            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        >
            <MenuItem
                onClick={() => handleNavigation('/myPage')}
            >
                <UserMenuContent userState={userState} />
            </MenuItem>
            {userState.is_admin && (
                <MenuItem>
                    <Button
                        fullWidth
                        variant="contained"
                        onClick={handleSync}
                        color="secondary"
                    >
                        Sync
                    </Button>
                </MenuItem>
            )}
            <MenuItem>
                <Button
                    fullWidth
                    variant="contained"
                    onClick={handleSignOut}
                    color="primary"
                >
                    Sign Out
                </Button>
            </MenuItem>
        </Menu>
    );

    return (
        <AppBar position="absolute" open={open}>
            <Toolbar
                sx={{
                    pr: '24px', // keep right padding when drawer closed
                }}
            >
                <IconButton
                    edge="start"
                    color="inherit"
                    aria-label="open drawer"
                    onClick={toggleDrawer}
                    sx={{
                        marginRight: '36px',
                        ...(open && { display: 'none' }),
                    }}
                >
                    <MenuIcon />
                </IconButton>
                <HeaderIcon
                    theme={theme}
                    onClickLogo={() => handleNavigation('/Project')}
                />
                <div className="flex-grow" />
                <SearchBar theme={theme} />
                <IconButton color="inherit">
                    <Badge badgeContent={4} color="secondary">
                        <NotificationsIcon />
                    </Badge>
                </IconButton>
                <IconButton
                    edge="end"
                    color="inherit"
                    onClick={handleUserMenuClick}
                >
                    <AccountCircle />
                </IconButton>
            </Toolbar>
            {renderUserMenu()}
        </AppBar>
    );
};

const SearchBar = ({ theme }) => (
    <div
        className="relative flex flex-row items-center justify-center w-64 h-10 bg-white rounded-sm"
        style={{
            marginRight: theme.spacing(2),
            border: '1px solid #a5a5a5',
        }}
    >
        <div
            style={{
                position: 'absolute',
                left: 5,
                top: 0,
                height: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                borderRadius: '0 4px 4px 0',
            }}
        >
            <SearchIcon />
        </div>
        <InputBase
            placeholder="Search…"
            style={{ marginLeft: theme.spacing(3), width: '80%' }}
        />
    </div>
);

const UserMenuContent = ({ userState }) => (
    <div className="flex flex-col space-y-2">
        {userState.logo !== null && (
            <Box
                component="img"
                src={userState.logo}
                alt="User Logo"
                backgroundColor="transparent"
                maxHeight={120}
                alignContent="center"
            />
        )}
        <div className="flex flex-row justify-between items-center">
            <PersonOutlineIcon fontSize="large" />
            <div className="flex flex-col justify-start px-4">
                <Typography color="neutral.light">
                    {userState.username}
                </Typography>
                <Typography sx={{ fontWeight: 'bold' }}>
                    {userState.company_name}
                </Typography>
            </div>
            <ArrowForwardIosIcon fontSize="small" color="neutral.light" />
        </div>
    </div>
);

export default Header;
