import React, { useState } from 'react';
import { useParams } from 'react-router-dom';

import {
    TextField,
    Button,
    Card,
    CardContent,
    Typography,
    Box,
    Container,
    Tooltip,
    IconButton,
} from '@mui/material';
import {
    CloudOutlined,
    KeyOutlined,
    VpnKeyOutlined,
    PublicOutlined,
    InfoOutlined,
    DeleteOutline,
    ContentCopyOutlined,
} from '@mui/icons-material';
import { styled } from '@mui/material/styles';

const StyledCard = styled(Card)(({ theme }) => ({
    maxWidth: 500,
    margin: '40px auto',
    boxShadow: '0 8px 24px rgba(0,0,0,0.12)',
    borderRadius: 16,
    background: 'linear-gradient(145deg, #ffffff 0%, #f5f5f5 100%)',
}));

const Form = styled('form')({
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
});

const RegisterAzure = ({ onSuccess, onClose }) => {

    const handleSubmit = async (e) => {
        // e.preventDefault();
        // try {
        //     const data = await callApi(() =>
        //         fetchCreateAWSCredential(iid, formData),
        //     );
        //     onSuccess();
        //     onClose();
        // } catch {
        //     onClose();
        // }
    };

    return (
        <Container maxWidth="sm">
            <StyledCard>
                <CardContent sx={{ p: 4 }}>
                    <Box display="flex" alignItems="center" mb={3}>
                        <CloudOutlined
                            sx={{ fontSize: 40, color: 'primary.main', mr: 2 }}
                        />
                        <Typography variant="h4" fontWeight="600">
                            Azure Connection
                        </Typography>
                    </Box>
                    <Form onSubmit={handleSubmit}>
                        <Button
                            variant="contained"
                            size="large"
                            type="submit"
                            sx={{
                                mt: 2,
                                height: 56,
                                background:
                                    'linear-gradient(45deg, #2196F3 30%, #21CBF3 90%)',
                                boxShadow:
                                    '0 3px 5px 2px rgba(33, 203, 243, .3)',
                                '&:hover': {
                                    background:
                                        'linear-gradient(45deg, #1976D2 30%, #00BCD4 90%)',
                                },
                            }}
                            startIcon={<CloudOutlined />}
                        >
                            {/* Connect to Azure */}
                            Service Unavailable
                        </Button>
                    </Form>
                </CardContent>
            </StyledCard>
        </Container>
    );
};

export default RegisterAzure;
