import React from 'react';

import { Typography, Box, Avatar, Grid } from '@mui/material';

import MainCard from './MainCard';
import styled from '@mui/material/styles/styled';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import AzureLogo from '../../assets/images/Azure_Logo.svg';
import theme from '../../theme';

const AzureCard = ({ onClickCard, selected, disable }) => {

    const AzureCardWrapper = styled(MainCard)(({ theme }) => ({
        backgroundColor: disable ? '#a0a0a0' : '#fff',
        color: '#000',
        overflow: 'hidden',
        position: 'relative',
        minWidth: 350,
        height: 180,
        opacity: disable ? 0.3 : 1,
        border: selected ? '3px solid #0080ff' : '1px solid #e0e0e0',
        '&:after': {
            content: '""',
            position: 'absolute',
            width: 210,
            height: 210,
            background: '#0080ff',
            borderRadius: '50%',
            top: -85,
            right: -95,
            [theme.breakpoints.down('sm')]: {
                top: -105,
                right: -140,
            },
        },
        '&:before': {
            content: '""',
            position: 'absolute',
            width: 210,
            height: 210,
            background: '#d8ebff',
            borderRadius: '50%',
            top: -125,
            right: -15,
            [theme.breakpoints.down('sm')]: {
                top: -155,
                right: -70,
            },
        },
    }));

    return (
        <AzureCardWrapper
            border={false}
            content={false}
            onClick={disable ? null : (e) => onClickCard(e, 'Azure')}
        >
            <Box sx={{ p: 2.25 }}>
                <Grid container direction="column">
                    <Grid item>
                        <Grid container justifyContent="space-between">
                            <Grid item></Grid>
                            <Grid item>
                                <Avatar
                                    variant="rounded"
                                    // sx={{
                                    //     ...theme.typography.commonAvatar,
                                    //     ...theme.typography.mediumAvatar,
                                    //     backgroundColor:
                                    //         theme.palette.secondary.dark,
                                    //     color: theme.palette.secondary[200],
                                    //     zIndex: 1,
                                    // }}
                                    aria-controls="menu-earning-card"
                                    aria-haspopup="true"
                                >
                                    <MoreHorizIcon fontSize="inherit" />
                                </Avatar>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item>
                        <Grid container alignItems="center">
                            <Grid item>
                                <Avatar
                                    sx={{
                                        ...theme.typography.smallAvatar,
                                        backgroundColor: 'transparent',
                                        marginRight: '10px',
                                        border: '1px solid dark',
                                    }}
                                >
                                    <img src={AzureLogo} alt="shape" />
                                </Avatar>
                            </Grid>
                            <Grid item>
                                <Typography
                                    sx={{
                                        fontSize: '2.125rem',
                                        fontWeight: 500,
                                        mr: 1,
                                        mt: 1.75,
                                        mb: 0.75,
                                    }}
                                >
                                    Azure
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item sx={{ mb: 1.25 }}>
                        <Typography
                            sx={{
                                fontSize: '1rem',
                                fontWeight: 500,
                                color: theme.palette.secondary[200],
                            }}
                        >
                            Microsoft Azure
                        </Typography>
                    </Grid>
                </Grid>
            </Box>
        </AzureCardWrapper>
    );
};

export default AzureCard;
